import { MatxLoadable } from "matx";

const Analytics = MatxLoadable({
  loader: () => import("./Analytics"),
});

const dashboardRoutes = [
  {
    path: "/dashboard/analytics",
    component: Analytics,
    auth: "SUPER ADMIN",
  },
];

export default dashboardRoutes;
