import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";

import AcadSessionRoutes from "./Pages/AcadSession/AcadSessionRoutes";
import CellRoutes from "./Pages/Cell/CellRoutes";
import VerticalRoutes from "./Pages/Vertical/VerticalRoutes";
import ContactRoutes from "./Pages/ContactUs/contactRoutes";
import TeamRoutes from "./Pages/Team/TeamRoutes";
import CollabRoutes from "./Pages/Collaboration/CollabRoutes";
import EventsRoutes from "./Pages/Events/EventsRoutes";
import InitiativeRoutes from "./Pages/Initiatives/InitiativeRoutes";
import LatestActivityRoutes from "./Pages/LatestActivity/ActivityRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...AcadSessionRoutes,
  ...CellRoutes,
  ...ContactRoutes,
  ...CollabRoutes,
  ...VerticalRoutes,
  ...EventsRoutes,
  ...InitiativeRoutes,
  ...LatestActivityRoutes,
  ...sessionRoutes,
  ...TeamRoutes,
  ...dashboardRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
