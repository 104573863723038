import { MatxLoadable } from "matx";

const ContactUs = MatxLoadable({
  loader: () => import("./Contact"),
});

const ContactRoutes = [
  {
    path: "/contact",
    component: ContactUs,
  },
];

export default ContactRoutes;
