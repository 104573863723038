import { MatxLoadable } from "matx";

const Team = MatxLoadable({
  loader: () => import("./Team"),
});

const TeamForm = MatxLoadable({
  loader: () => import("./TeamForm"),
});

const TeamGallery = MatxLoadable({
  loader: () => import("./TeamGallery"),
});

const TeamRoutes = [
  {
    path: "/team/gallery",
    component: TeamGallery,
  },
  {
    path: "/team/list",
    component: Team,
  },
  {
    path: "/team/add",
    component: TeamForm,
  },
  {
    path: "/team/edit/:TeamId",
    component: TeamForm,
  },
];

export default TeamRoutes;
