import { MatxLoadable } from "matx";

const CellList = MatxLoadable({
  loader: () => import("./CellList/Cell"),
});

const CellForm = MatxLoadable({
  loader: () => import("./CellList/CellForm"),
});

const CellGallery = MatxLoadable({
  loader: () => import("./CellGallery"),
});

const CellRoutes = [
  {
    path: "/cell/gallery",
    component: CellGallery,
  },
  {
    path: "/cells",
    component: CellList,
  },
  {
    path: "/cell/add",
    component: CellForm,
  },
  {
    path: "/cell/edit/:CellId",
    component: CellForm,
  },
];

export default CellRoutes;
