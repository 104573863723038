import { combineReducers } from "redux";
import LayoutReducer from "./LayoutReducer";
import AuthReducer from "./authReducer";

const RootReducer = combineReducers({
  layout: LayoutReducer,
  auth: AuthReducer,
});

export default RootReducer;
