import { MatxLoadable } from "matx";

const Session = MatxLoadable({
  loader: () => import("./SessionForm"),
});

const SessionRoutes = [
  {
    path: "/academic/sessions",
    component: Session,
  },
];

export default SessionRoutes;
