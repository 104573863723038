import { MatxLoadable } from "matx";

const ActivityList = MatxLoadable({
  loader: () => import("./Activity"),
});

const ActivityForm = MatxLoadable({
  loader: () => import("./ActivityForm"),
});

const ActivityRoutes = [
  {
    path: "/latest-activities",
    component: ActivityList,
  },
  {
    path: "/latest-activity/add",
    component: ActivityForm,
  },
  {
    path: "/latest-activity/edit/:LAcID",
    component: ActivityForm,
  },
];

export default ActivityRoutes;
