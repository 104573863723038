import { MatxLoadable } from "matx";

const Vertical = MatxLoadable({
  loader: () => import("./VerticalList/Vertical"),
});

const VerticalForm = MatxLoadable({
  loader: () => import("./VerticalList/VerticalForm"),
});

const VerticalGallery = MatxLoadable({
  loader: () => import("./VerticalGallery"),
});

const VerticalRoutes = [
  {
    path: "/vertical/gallery",
    component: VerticalGallery,
  },
  {
    path: "/verticals",
    component: Vertical,
  },
  {
    path: "/vertical/add",
    component: VerticalForm,
  },
  {
    path: "/vertical/edit/:VertId",
    component: VerticalForm,
  },
];

export default VerticalRoutes;
