import { API } from "../../../backend";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import history from "../../../history";
import {
  SET_CURRENT_USER,
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
} from "./types";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const isEmpty = require("is-empty");

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
  });
  axios
    .post(`${API}/login/`, userData)
    .then((res) => {
      const is_superuser = res.data.is_superuser;
      if (is_superuser) {
        const { access } = res.data;
        // Save token to localStorage
        localStorage.setItem("jwtToken", access);
        localStorage.setItem("userData", JSON.stringify(res.data));
        // Set token to Auth header
        setAuthToken(localStorage.getItem("jwtToken"));
        // Set current user
        dispatch(setCurrentUser(res.data));
        // pushing to home page after login
        history.push({
          pathname: "/dashboard/analytics",
        });
        // login messag on succes
        toast("Logged in Successfully", { type: "success" });
        // set loading to false
        dispatch(setUserLoaded());
      } else {
        toast("Access Denied. Not a super user", {
          type: "warning",
        });
        // set loading to false
        dispatch(setUserLoaded());
      }
    })
    .catch((err) => {
      // error message
      toast(err.response.data.detail, { type: "error" });
      dispatch(setAuthError());
    });
};

// Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

// // User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// // User loading
export const setUserLoaded = () => {
  return {
    type: USER_LOADED,
  };
};

export const setAuthError = () => {
  return {
    type: AUTH_ERROR,
  };
};

// Log user out
export const logoutUser = (history) => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("userData");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {}
  history.push({
    pathname: "/session/signin",
  });
  dispatch(setCurrentUser({}));
  toast("Logged out Successfully", { type: "warning" });
};
