import { MatxLoadable } from "matx";

const Events = MatxLoadable({
  loader: () => import("./Event/Event"),
});

const EventsForm = MatxLoadable({
  loader: () => import("./Event/EventForm"),
});

const EventGalleryList = MatxLoadable({
  loader: () => import("./Gallery/index"),
});

const EventGallery = MatxLoadable({
  loader: () => import("./Gallery/Gallery"),
});

const EventsRoutes = [
  {
    path: "/events",
    component: Events,
  },
  {
    path: "/event/add",
    component: EventsForm,
  },
  {
    path: "/event/edit/:EventId",
    component: EventsForm,
  },
  {
    path: "/event/galleries",
    component: EventGalleryList,
  },
  {
    path: "/event/gallery/:EvtGalId",
    component: EventGallery,
  },
];

export default EventsRoutes;
