import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "../history";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { setCurrentUser } from "./redux/actions/authActions";
import MatxLayout from "./MatxLayout/MatxLayout";
import setAuthToken from "./utils/setAuthToken";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info
  const user = JSON.parse(localStorage.userData);
  // Set user and isAuthenticated
  Store.dispatch(setCurrentUser(user));
}

class App extends React.Component {
  render() {
    return (
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <MatxTheme>
            <Router history={history}>
              <MatxLayout />
            </Router>
          </MatxTheme>
        </Provider>
      </AppContext.Provider>
    );
  }
}

export default App;
