import { MatxLoadable } from "matx";

const Initiatives = MatxLoadable({
  loader: () => import("./Initiatives/Initiative"),
});

const InitiativeForm = MatxLoadable({
  loader: () => import("./Initiatives/InitiativeForm"),
});

const InitGalleryList = MatxLoadable({
  loader: () => import("./Gallery/index"),
});

const InitGallery = MatxLoadable({
  loader: () => import("./Gallery/Gallery"),
});

const InitiativeRoutes = [
  {
    path: "/initiatives",
    component: Initiatives,
  },
  {
    path: "/initiative/add",
    component: InitiativeForm,
  },
  {
    path: "/initiative/edit/:InitId",
    component: InitiativeForm,
  },
  {
    path: "/initiative/galleries",
    component: InitGalleryList,
  },
  {
    path: "/initiative/gallery/:InitGalId",
    component: InitGallery,
  },
];

export default InitiativeRoutes;
