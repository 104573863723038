import { MatxLoadable } from "matx";

const Collaboration = MatxLoadable({
  loader: () => import("./Collabs/Collaboration"),
});

const CollabForm = MatxLoadable({
  loader: () => import("./Collabs/CollabForm"),
});

const TypeForm = MatxLoadable({
  loader: () => import("./CollabType/TypeForm"),
});

const CollabRoutes = [
  {
    path: "/partners",
    component: Collaboration,
  },
  {
    path: "/partner/add",
    component: CollabForm,
  },
  {
    path: "/partner/edit/:CollabId",
    component: CollabForm,
  },
  { path: "/collab-types", component: TypeForm },
];

export default CollabRoutes;
